<template>
  <div class="login">
    <div class="block">
      <base-input-password v-model="password" name="API Key Password">
        <template #subNameContent>
          <Popper arrow hover placement="top">
            <QuestionMark/>
            <template #content>
              <div class="html-block-popper">Use the password of the API Key set for this docker</div>
            </template>
          </Popper>
        </template>
      </base-input-password>
      <base-button @click="login" class="login-button">Login</base-button>
    </div>
  </div>
</template>

<script>
import BaseInputPassword from "../controls/BaseInputPassword";
import BaseButton from "../controls/BaseButton";
import {computed, ref, watch} from "vue";
import {useAppStore} from "@/store/appStore";
import QuestionMark from '@/assets/icons/question-mark.svg';

export default {
  components: {
    QuestionMark,
    BaseButton,
    BaseInputPassword,
  },
  props: {},
  setup(props) {
    const store = useAppStore();
    const password = ref("")

    function login() {
      console.log('test')
      store.getConfig(password.value).then(data => {

      })
    }

    return {
      store,
      login,
      password,
    };
  },
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-button {
    margin: 0 auto;
    margin-top: 15px
  }
}


.block {
  width: 400px;


}

</style>