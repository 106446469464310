<template>
  <modal :visible="show" :close="onClose" className="form">
    <div class="header">
      <div>Attention</div>
      <div class="modal-close" @click="onClose">
        <CloseIcon/>
      </div>
    </div>
    <div class="body attention-modal-text">
      <div class="attention-modal-title text-center">
        <slot name="title"></slot>
      </div>
      <div class="text-center mt-3">
        <slot name="text"></slot>
      </div>
    </div>
    <div class="footer">
      <BaseButton class="mr-2" @click="onApprove">
        Apply
      </BaseButton>
      <BaseButton outline @click="onClose">Close</BaseButton>
    </div>
  </modal>
</template>

<script>

import {ref, watch} from 'vue';
import BaseButton from '../controls/BaseButton';
import BaseInputString from '../controls/BaseInputString';
import CloseIcon from '@/assets/icons/close.svg';

export default {
  components: {BaseButton, BaseInputString, CloseIcon},
  name: 'ConfirmationModal',
  props: {
    show: Boolean,
    message: "",
    loadingApprove: Boolean,
  },
  emits: ['approve', 'close'],
  setup(props, {emit}){
    const approve = ref("");

    watch(() => props.show, function (){
      approve.value = "";
    });
    
    return {
      approve,
      onApprove,
      onClose
    }
    
    function onApprove(){
      emit('approve');
    } 
    
    function onClose(){
      emit('close');
    } 
  }
}
</script>

<style scoped lang="scss">
  .attention-modal-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
  .attention-modal-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
</style>