import { defineStore } from 'pinia'
import axios from "axios";

export const useAppStore = defineStore('useAppStore', {
    state: () => {
        return {
            serverInfo: null,
            statsSettings: {
                enabled: false,
                interval: 5,
            },
            config: {},
            authorized: false,
        }
    },
    actions: {
        async setVersion(data) {
            return axios.post('/updater/setVersion', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async versionList() {
            return axios.post('/updater/versionList', {
                data: {}
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },
        async saveDeepgramSettings(data) {
            return axios.post('/integrations/saveDeepgramSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getDeepgramSettings(data) {
            return axios.post('/integrations/getDeepgramSettings', {
                data: data
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },
        async saveSpeechmaticsSettings(data) {
            return axios.post('/integrations/saveSpeechmaticsSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getSpeechmaticsSettings(data) {
            return axios.post('/integrations/getSpeechmaticsSettings', {
                data: data
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },
        
        async saveWhisperSettings(data) {
            return axios.post('/integrations/saveWhisperSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getWhisperSettings(data) {
            return axios.post('/integrations/getWhisperSettings', {
                data: data
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },

        async saveCleanvoiceSettings(data) {
            return axios.post('/integrations/saveCleanvoiceSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getCleanvoiceSettings(data) {
            return axios.post('/integrations/getCleanvoiceSettings', {
                data: data
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },

        async saveGoogleSttSettings(data) {
            return axios.post('/integrations/saveGoogleSttSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getGoogleSttSettings(data) {
            return axios.post('/integrations/getGoogleSttSettings', {
                data: data
            }).then((resp) => {
                if(resp.data?.success){
                    return resp.data.data
                }
                return {}
            })
        },
        async getStatsSettings() {
            try{
                const resp = await axios.post('/statistics/getStatsSettings');
                if(resp.data.success){
                    this.statsSettings = resp.data.data
                    return {...this.statsSettings}
                }
            } catch (e) {
                console.log(e)
            }
        },
        async setStatsSettings(data) {
            return axios.post('/statistics/setStatsSettings', {
                data: data
            }).then((resp) => {
                return resp
            })
        },
        async getConfig(password = null) {
            if (this.authorized){
                return {...this.config}
            }

            try{
                const resp = await axios.post('/license/getConfig', {
                    data: {
                        password: password ? password : this.config?.apiKeyPassword
                    }
                });
                    if(resp.data.success){
                        this.authorized = true
                        this.config = resp.data.data
                        return {...this.config}
                    }
            }
            catch(err){
                this.authorized = false
            }
        },
        getServerInfo(){
            axios.post('/license/getServerInfo').then(({data}) => {
                if(data.success){
                    this.serverInfo = data.data
                }
            })
        },
        async activate(data){
            try{
                return axios.post('/license/activate', {
                    data: data
                }).then((resp) => {
                    this.getServerInfo()
                    this.getConfig()
                    return resp
                })
            }
            catch(err){

            }
        },
        async setLicense(license){
            return axios.post('/license/setLicense', {
                data: {
                    license
                }
            }).then(({data}) => {
                this.getServerInfo()
                return data
            })
        },

        async setCredits(license){
            return axios.post('/license/setCredits', {
                data: {
                    license
                }
            }).then(({data}) => {
                this.getServerInfo()
                return data
            })
        },

        sendLogs() {
            axios.post('/log/send', {
                data: {

                }
            }).then(r => {
                return r
            })
        },

        deleteLicense(license){
            return axios.post('/license/resetServer').then(({data}) => {
                this.getServerInfo()
            })
        },

        checkUpdates() {
            return axios.post('/updater/checkUpdates');
        },

        runUpdates() {
            return axios.post('/updater/runUpdate');
        }
    },
})
