<template>
  <teleport to="#modals">
    <div v-if="visible" class="modal">
      <div :class="'modal-content '+(className ? className : '')" @click="(e) => e.stopPropagation()">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      visible: Boolean,
      className: {
        type: String,
        required: false
      },
      close: {
        type: Function,
        required: false
      },
    },
    data() {
      return {};
    },
    mounted() {
      document.addEventListener('keydown', this.onKeyDown);
    },
    unmounted() {
      document.removeEventListener('keydown', this.onKeyDown);
    },
    methods: {
      onKeyDown: function(event) {
        const that = this
        const key = event.key; // const {key} = event; in ES6+
        if (key === "Escape") {
          that.close();
        }
      },
      onClose: function (event){
        if(this.close){
          this.close(event);
        }
      }
    },
  }
</script>

<style lang="scss" src="../../assets/styles/modal.scss"></style>
