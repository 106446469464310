<template>
  <div class="base-tabs">
    <div class="base-tabs-header">
      <div
          v-for="(tab, index) in items"
          :key="index"
          class="base-tab"
          :class="{'base-tab-active': tab.isActive}"
          @click="changeTab(index)"
      >
        {{ tab.name }}
      </div>
    </div>
    <div class="base-tab-content">
      <component v-bind:is="activeTab.component" v-bind="activeTab.props"></component>
    </div>
  </div>
</template>
<script>

import {ref, computed} from "vue";

export default {
  components: {},
  name: 'BaseTabs',
  props: {
    tabs: Object, //{name, component, props}
  },
  setup(props){
    const items = ref(props.tabs.map((e, index) => {
      return {...e, isActive: index === 0};
    }));
    const showModal = ref(false);
    
    const activeTab = computed(() => {
      return items.value.find((e) => e.isActive);
    });
    
    return {
      items,
      showModal,
      
      activeTab,
      
      changeTab
    }
    
    function changeTab(tabIndex) {
      items.value.forEach((e, index) => {
        e.isActive = tabIndex === index;
      })
    }
  }
}
</script>