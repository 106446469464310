<template>
  <base-button v-if="showButton" @click="checkUpdates" :loading="loading" class="position-static">Check Updates</base-button>

  <modal :visible="!!info" :close="onCloseInfo" className="form">
    <div class="header">
      <div>Attention</div>
      <div class="modal-close" @click="onCloseInfo">
        <CloseIcon/>
      </div>
    </div>
    <div class="body attention-modal-text">
        <div v-if="upgrading.inProgress" style="margin-bottom: 12px">
            <progress-bar bgcolor="#4E9BF0" :completed="upgrading.percent"/>
        </div>
      <div class="text-center">{{ info }}</div>
    </div>
    <div class="footer">
      <base-button class="mx-auto" outline @click="onCloseInfo">Close</base-button>
    </div>
  </modal>

  <modal :visible="!!updateInfo" :close="onUpdateClose" className="form">
    <div class="header">
      <div>New version check</div>
      <div class="modal-close" @click="onUpdateClose">
        <CloseIcon/>
      </div>
    </div>
    <div class="body attention-modal-text">
      <div class="text-center">
        <template v-if="updateInfo.mandatory">
          <div>Mandatory update is available!</div>
          <div>New Version: {{updateInfo.mandatory.version}}</div>
        </template>
        <template v-else-if="updateInfo.recommended">
          <div>Recommended update is available!</div>
          <div>New Version: {{updateInfo.recommended.version}}</div>
        </template>
        <template v-else>
          The latest EMLO docker version already installed
        </template>
      </div>
    </div>
    <div class="footer">
      <template v-if="updateInfo.mandatory || updateInfo.recommended">
        <base-button class="mx-1" outline @click="onUpdateClose">Close</base-button>
        <base-button class="mx-1" @click="onApplyUpdate">Apply</base-button>
      </template>
      <template>
        <base-button outline class="mx-auto" @click="onUpdateClose">Close</base-button>
      </template>
    </div>
  </modal>


</template>

<script setup>
import ProgressBar from "./ProgressBar.vue";
import BaseButton from "./BaseButton";
import {useAppStore} from "@/store/appStore";
import {reactive, shallowRef} from "vue";
import Modal from "@/components/controls/modal";
import CloseIcon from '@/assets/icons/close.svg';

const store = useAppStore();
const info = shallowRef("");
const loading = shallowRef(false);
const updateInfo = shallowRef(null);
const upgrading = reactive({
    inProgress: false,
    percent: 10,
    version: "",
    ticker: 0,
});

const props = defineProps({
  showButton: {
    type: Boolean,
    default: true,
  },
});

function checkUpdates() {
  loading.value = true;
  store.checkUpdates().then(r => {
    if (!r.data.success) {
      return Promise.reject(r.data);
    }
    return r.data.data;
  }).then(info => {
    updateInfo.value = info;
    loading.value = false;
  }, (err) => {
    if(err?.errorCode === "not_activated"){
      info.value = err.error
    } else {
      info.value = "Failed to get update information";
    }

    loading.value = false;
  });
}

function onUpdateClose() {
  updateInfo.value = null;
}
function onCloseInfo() {
  info.value = "";
}


const showUpdater = (version, callback) => {
  info.value = "Update process has been started. Please wait...";

  upgrading.inProgress = true;
  upgrading.version = version || updateInfo.value.recommended?.version || updateInfo.value.mandatory?.version;

  updateInfo.value = null;

  upgrading.ticker = setInterval(() => {
    if (upgrading.percent < 98) {
      upgrading.percent += 2;
    }

    if (store.serverInfo?.siteVersion === upgrading.version) {
      upgrading.inProgress = false;
      upgrading.percent = 10;
      info.value = "Upgrade completed successfully";
      clearInterval(upgrading.ticker)
      callback && callback();
    }
    store.getServerInfo()

  }, 2000)
}

defineExpose({
  showUpdater,
  hideUpdater: () => {
    clearInterval(upgrading.ticker)
    upgrading.inProgress = false;
    upgrading.percent = 10;
    info.value = "";
  }
})


function onApplyUpdate() {
    showUpdater();
    store.runUpdates();
}
</script>
