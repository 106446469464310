<template>
  <div class="base-input" :class="this.class">
    <div v-if="name" class="d-flex flex-row justify-content-between">
      <div class="base-input-label">
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <label class="base-input-container textarea" :class="{'base-input-disabled': disabled, 'base-input-money': money}">
      <span v-if="money">$</span>
      <textarea
          v-model="value"
          :rows="rows"
          class="base-input-control"
          :disabled="disabled"
          @blur="onBlur"
          :type="type"/>
    </label>
    <div v-if="error" class="base-input-error">
     <error/>
      <div class="base-input-error-text">{{error}}</div>
    </div>
  </div>
</template>

<script>

import Error from '@/assets/icons/error.svg';

export default {
  components: {Error},
  name: 'BaseTextarea',
  props: {
    name: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {type: Boolean, required: false, default: false},
    modelValue: String,
    money: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    },
    error: String,
    class: String,
    type: {type: String, default: "text"}
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods:{
    onBlur(){
      this.$emit('blur', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>

.base-input{
  display: flex;
  flex-direction: column;
  .base-input-label{
    margin-bottom: 12px;
  }
  .base-input-container{
    padding: 17px 24px;
    border: 1px solid white;
    border-radius: 12px;
    textarea{
      //resize: none;
    }
  }
  .base-input-disabled{
    pointer-events: none;
  }
  .base-input-error{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 5px;
    svg{
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
  .base-input-error-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
