import { createApp } from 'vue'
import router from './routes';
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Popper from "vue3-popper";
import Multiselect from '@vueform/multiselect';
import BootstrapVue3 from 'bootstrap-vue-3';
import { createPinia } from 'pinia'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

//import '@vueform/toggle/themes/default.css'
import '@vueform/multiselect/themes/default.css'
import '@vueform/toggle/themes/default.css'
import './assets/styles/base.scss'
import './assets/styles/table.scss'
import './assets/styles/modal.scss'
import './assets/styles/themes.scss'

import {baseUrl} from "./config";

const pinia = createPinia()
const app = createApp(App);
app.use(pinia)
app.use(BootstrapVue3);
axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';
app.use(VueAxios, axios);
app.use(router);
app.component('modal', require("./components/controls/modal").default);
app.component("Popper", Popper);
app.component("Multiselect", Multiselect);

app.mount('#app');