<template>
  <div class="home-page">
    <deepgram-block/>
    <SpeechmaticsBlock class="mt-4"/>
    <WhisperBlock class="mt-4"/>
<!--    <google-block class="mt-4"/>-->
<!--    <cleanvoice-block class="mt-4"/>-->
  </div>
</template>

<script>
import DeepgramBlock from "./integraions/deepgram.vue";
import SpeechmaticsBlock from "./integraions/Speechmatics.vue";
import WhisperBlock from "./integraions/Whisper.vue";
/*import GoogleBlock from "./integraions/google.vue";*/
/*import CleanvoiceBlock from "./integraions/cleanvoice.vue";*/

export default {
  components: {
    DeepgramBlock,
    SpeechmaticsBlock,
    WhisperBlock,
    /*GoogleBlock,*/
    /*CleanvoiceBlock,*/
  },
  props: {},
  setup(props) {
  },
}
</script>

<style scoped lang="scss">
.home-page {
  width: 100%;
  border-radius: 12px;
}

</style>
