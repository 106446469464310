<template>
  <div class="layout">
    <div class="main_header">
      <div class="logo">
        <img src="/logo.png"/>
      </div>
    </div>
    <div class="main_content">
      <div class="page">
        <router-view/>
      </div>
      <div class="info">
        <div class="info__inner">
          <div class="info__block">
            <div class="info__block__header">Docker Info</div>
            <div class="info__block__body">
              <div class="info__block__body__line">
                <div>Status:</div>
                <div>OK</div>
              </div>
              <div class="info__block__body__line">
                <div>LVA Core Version:</div>
                <div>{{ store.serverInfo?.dockerVersion }}</div>
              </div>
              <div class="info__block__body__line">
                <div>Docker version:</div>
                <div>{{ store.serverInfo?.siteVersion }}</div>
              </div>
              <div v-if="store.serverInfo?.plugName" class="info__block__body__line">
                <div>Plug Name:</div>
                <div>{{ store.serverInfo?.plugName }}</div>
              </div>
              <div v-if="store.serverInfo?.plugName" class="info__block__body__line">
                <div>Seats Count:</div>
                <div>{{ store.serverInfo?.seatsCount }}</div>
              </div>
              <div v-if="!store.serverInfo?.plugName" class="info__block__body__line">
                <div>License not set</div>
                <div></div>
              </div>
              <div class="info__block__body__line">
                <update-checker />
              </div>
            </div>
          </div>
          <div class="info__block">
            <div class="info__block__header">Activity Statistic</div>
            <div class="info__block__body">
              <div class="info__block__body__line">
                <div>Total Minutes:</div>
                <div>{{store.serverInfo?.totalMinutes}}</div>
              </div>
              <div class="info__block__body__line">
                <div>Total Files Processed:</div>
                <div>{{store.serverInfo?.totalFilesProcessed}}</div>
              </div>
              <div class="info__block__body__line">
                <div>Total Files Failures:</div>
                <div>{{store.serverInfo?.totalFilesFailures}}</div>
              </div>
              <div class="info__block__body__line">
                <div>Total Days Active:</div>
                <div>{{store.serverInfo?.totalDaysActive}}</div>
              </div>
              <div class="info__block__body__line">
                <div>Total Credits left:</div>
                <div>{{store.serverInfo?.creditLeft}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from './assets/logo.svg'
import {useAppStore} from "@/store/appStore";
import UpdateChecker from "@/components/controls/UpdateChecker";

export default {
  name: 'App',
  components: {UpdateChecker, Logo},
  setup() {
    const store = useAppStore();

    return {store};


  },
}
</script>

<style lang="scss">
.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main_content {
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.info{
  padding: 20px 0;
  min-width: 270px;
  width-width: 270px;
  &__inner{
    background-color: #182339;
    padding: 20px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
  }

  &__block {
    &__header {
      font-weight: 700;
      font-size: 18px;
    }

    &__body {
      padding: 20px 0;

      &__line{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }
}

.page {
  padding: 20px;
  flex-grow: 1;
  display: flex;
}

.main_header {
  background-color: #1d2a4c;
  padding: 20px;

  .logo {
    svg {
      fill: white;
    }
  }
}
</style>


