<template>
  <div v-show="show" class="base-preloader-container" :class="this.class">
    <div class="base-preloader"></div>
  </div>
</template>

<script>


export default {
  components: {},
  name: 'BasePreloader',
  props: {
    show: Boolean,
    class: String,
  },
}
</script>

<style lang="scss" scoped>
  .base-preloader-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(25px);
  }
  .base-preloader {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 10px solid white;
    border-top-color: blue;
    box-sizing: border-box;
    background: transparent;
    animation: loading 2.5s linear infinite;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(360deg);
    }
  }

</style>
