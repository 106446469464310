<template>
  <div class="home-page">
    <div class="row">
      <div class="block col" style="flex-grow: 1">
        <div class="block__inner">
          <div class="block__header">
            Versions
          </div>
          <div class="block__body">
            <div class="row">
              <div class="col-lg-9 mb-3">
                <BaseDropdown
                    v-model="reqData.version"
                    :error="errors.version"
                    name="Select Version"
                    is-required
                    :options="versionList"
                    style="max-width: 400px"
                />
              </div>
              <div class="col activate-row">
                <div>
                  <div class="base-input-label" style="margin-bottom: 12px;">&nbsp;</div>
                  <base-button @click="save" :disabled="!reqData.version">Update</base-button>
                  <div v-if="saveResp.message" class="col-12 activate-message">
                    <span :class="`${saveResp.success ? 'success' : 'error'}`">{{saveResp.message}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <BasePreloader :show="isShowPreloader"/>

    <UpdateChecker :show-button="false" ref="updaterRef"/>
  </div>
</template>

<script>
import BaseButton from "../controls/BaseButton";
import BaseInputString from "../controls/BaseInputString";
import BaseInputPassword from "../controls/BaseInputPassword";
import BaseDropdown from "../controls/BaseDropdown";
import {computed, onMounted, ref} from "vue";
import {useAppStore} from "@/store/appStore";
import {baseUrl} from "@/config";
import ConfirmationModal from "../modals/ConfirmationModal";
import BasePreloader from "../controls/BasePreloader";
import BaseCheckbox from "../controls/BaseCheckbox";
import Close from '@/assets/icons/close.svg';
import SuccessLarge from '@/assets/icons/success-large.svg';
import ErrorLarge from '@/assets/icons/error-large.svg';
import UpdateChecker from "@/components/controls/UpdateChecker.vue";

export default {
  components: {
    UpdateChecker,
    BaseCheckbox,
    BasePreloader,
    ConfirmationModal,
    BaseButton,
    BaseInputString,
    BaseInputPassword,
    BaseDropdown,
    Close,
    SuccessLarge,
    ErrorLarge,
  },
  props: {},
  setup(props) {

    const store = useAppStore();
    const isShowPreloader = ref(false)

    const errors = ref({
      version: "",
    })

    const reqData = ref({
      version: "",
    })

    const updaterRef = ref(null)

    const saveResp = ref({
      success: false,
      message: ""
    })

    const reset = () => {
      errors.value = {}
      saveResp.value = {
        success: false,
        message: ""
      }
    }

    const versions = ref([])
    const versionList = computed(() => {
      return versions.value.map(x => {
        return {
          value: x.version,
          label: x.version
        }
      })
    })

    onMounted(() => {
      reset()
      store.versionList().then(data => {
        versions.value = data
      })
    })

    const save = () => {
      reset()
      //isShowPreloader.value = true

      const data = versions.value.find(x => x.version === reqData.value.version)

      updaterRef.value.showUpdater(reqData.value.version, () => {
        window.location.reload()
      })

      store.setVersion({...data})
          .then((resp) => {
            if(resp.data.success){
              window.location.reload()
            }
          })
          .catch((err) => {
            //updaterRef.value.hideUpdater()
          })
          .finally(() => {
            //isShowPreloader.value = false
          })
    }

    return {

      updaterRef,
      versionList,
      errors,
      isShowPreloader,
      saveResp,
      reqData,
      store,
      save
    };
  },
}
</script>

<style scoped lang="scss">
.home-page {
  width: 100%;
  border-radius: 12px;
}

.block {
  &__inner {
    background-color: #182339;
    border-radius: 12px;
    height: 100%;
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    padding: 20px;
    border-bottom: solid 1px #a3aed0;;
  }

  &__body {
    padding: 20px;
  }
}

.r-systemId {
  &:deep(input) {
    text-align: center;
  }
}

.activate-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.activate-message{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  span {
    font-size: 14px;

    &.success {
      color: greenyellow;
    }

    &.error {
      color: red;
    }
  }
}

</style>
