<template>
  <div class="base-input" :class="this.class">
    <div class="d-flex flex-row justify-content-between base-input-label">
      <div>
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <label class="base-input-container">
      <input
          v-model="value"
          class="base-input-control" 
          :type="isViewPassword ? 'text' : 'password'">
      <div class="base-input-eye" @click="onEye">
        <span v-show="isViewPassword"><eye-close/></span>
        <span v-show="!isViewPassword"><eye/></span>
      </div>
    </label>
    <div v-if="error" class="base-input-error">
      <error/>
      <div class="base-input-error-text">
        {{error}}
      </div>
    </div>
  </div>
</template>

<script>

import Eye from '@/assets/icons/new-eye.svg';
import EyeClose from '@/assets/icons/new-eye-close.svg';
import Error from '@/assets/icons/error.svg';

export default {
  components: {Eye,EyeClose,Error},
  name: 'BaseInputString',
  props: {
    name: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    error: String,
    class: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isViewPassword: false, 
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onEye() {
      this.isViewPassword = !this.isViewPassword;
    }
  }
}
</script>

<style lang="scss" scoped>

.base-input{
  display: flex;
  flex-direction: column;
  .base-input-label{
    position: relative;
    margin-bottom: 12px;
  }
  .base-input-container{
    position: relative;
    padding: 17px 50px 17px 24px;
    margin-bottom: 5px;
    border: 1px solid white;
    border-radius: 12px;
  }
  .base-input-eye{
    position: absolute;
    cursor: pointer;
    right: 17px;
  }
  .base-input-error{
    display: flex;
    flex-direction: row;
    align-items: center;
    svg{
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .base-input-error-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
