<template>
  <BaseTabs :tabs="tabs" style="width: 100%"/>
</template>
<script setup>
import BaseTabs from "@/components/controls/BaseTabs.vue";
import MainTab from "@/components/tabs/main.vue";
import IntegrationsTab from "@/components/tabs/integrations.vue";
import VersionsTab from "@/components/tabs/versions.vue";
import {shallowRef} from "vue";

const tabs = [
  {
    id: 'main',
    name: 'Main',
    component: shallowRef(MainTab),
  },
  {
    id: 'integrations',
    name: 'Integrations',
    component: shallowRef(IntegrationsTab),
  },
  {
    id: 'versions',
    name: 'Versions',
    component: shallowRef(VersionsTab),
  }
]
</script>
