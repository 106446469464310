<template>
  <div class="base-input" :class="this.class">
    <div v-if="name" class="d-flex flex-row justify-content-between">
      <div class="base-input-label">
        {{name}}
        <span v-if="isRequired" class="input-required">*</span>
      </div>
      <div>
        <slot name="subNameContent"></slot>
      </div>
    </div>
    <div class="base-dropdown-container">
      <Multiselect
          :mode="mode"
          :multipleLabel="labelFunction" 
          :hideSelected="false"  
          :searchable="searchable" 
          :autocomplete="autocomplete"
          :canClear="canClear"
          :canDeselect="canClear"
          
          v-model="value" 
          :options="options"
      />
    </div>
    <div v-if="error" class="base-input-error">
      <error/>
      {{error}}
    </div>
  </div>
</template>

<script>

import Error from '@/assets/icons/error.svg';

export default {
  components: {Error},
  name: 'BaseInputString',
  props: {
    name: String,
    isRequired: {
      type: Boolean,
      default: false
    },
    modelValue: String | Array,
    options: Array,
    searchable: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    mode: {
      type: String,
      default: 'single'
    },
    canClear: {
      type: Boolean,
      default: true
    },
    error: String,
    class: String,
  },
  emits: ['update:modelValue'],
  setup(){
    return {

    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    labelFunction(n) {
      return `${n.length} Selected`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-input{
    display: flex;
    flex-direction: column;
    .base-input-label{
      margin-bottom: 12px;
    }
    .base-input-error{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
      svg{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .base-dropdown-container{
    &:deep(.multiselect){
      height: 48px;
      padding: 17px 24px;
      border: 1px solid white;
      border-radius: 12px;
      background-color: inherit;
      outline: none;
      font-family: inherit;
      font-size: inherit;
      &.is-active{
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .multiselect-caret{
          transform: rotate(-90deg);
        }
      }
      .multiselect-single-label-text{
        font-weight: inherit;
      }
      .multiselect-search,
      .multiselect-multiple-label, 
      .multiselect-placeholder, 
      .multiselect-single-label{
        padding-left: 24px;
      }
      .multiselect-clear{
        padding: 0;
      }
      .multiselect-clear-icon {
        width: 24px;
        height: 24px;
        -webkit-mask-image: url('../../assets/icons/close.css.svg');
        mask-image: url('../../assets/icons/close.css.svg');
        background-color: white;
        mask-size: unset;
      }
      .multiselect-caret {
        width: 24px;
        height: 24px;
        -webkit-mask-image: url('../../assets/icons/arrow.css.svg');
        mask-image: url('../../assets/icons/arrow.css.svg');
        transform: rotate(90deg);
        background-color: white;
        mask-size: unset;
        margin: 0;
      }
      .multiselect-dropdown{
        border: 1px solid white;
        border-top: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
      .multiselect-search{
        background-color: inherit;
      }
    }
  }
</style>
