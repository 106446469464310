<template>
  <label class="base-checkbox" :class="(radioButton ? 'base-radio ' : '') + this.class">
    <input
        type="checkbox"
        v-model="value"
    >
    <span class="base-checkbox-checkmark" :class="{'base-checkbox-checkmark-mr': name, 'base-checkbox-checkmark-checked': value}">
      <span v-show="value && !radioButton"><Check/></span>
      <span v-show="value && radioButton" class="base-radio-check"></span>
    </span>
    <span v-if="html" v-html="name" />
    <span v-else>{{name}}</span>
  </label>
</template>

<script>

import Check from '@/assets/icons/check.svg';

export default {
  components: {Check},
  name: 'BaseCheckbox',
  props: {
    name: String,
    modelValue: Boolean,
    radioButton: Boolean,
    class: String,
    html: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-checkbox {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;
  }
  /* Hide the browser's default checkbox */
  .base-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .base-checkbox-checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 18px;
    min-width: 18px;
    border: 2px solid white;
    border-radius: 2px;
    > span {
      display: flex;
    }
  }
  .base-checkbox-checkmark-mr{
    margin-right: 10px;
  }
  
  .base-radio .base-checkbox-checkmark{
    border-radius: 50%;
    border: 2px solid #A3AED0;
    .base-radio-check{
      min-height: 8px;
      min-width: 8px;
      border-radius: 50%;
    }
  }
  
  /* On mouse-over, add a grey background color */
  .base-checkbox:hover input ~ .base-checkbox-checkmark {
    background-color: white;
  }
  
  /* When the checkbox is checked, add a blue background */
  .base-checkbox-checkmark-checked {
    background-color: white;
    border: none;
  }
  
  .base-radio .base-checkbox-checkmark-checked{
    background-color: unset;
    .base-radio-check{
      background-color: white;
    }
  }

</style>
