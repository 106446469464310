<template>
  <div class="home-page">
    <div class="row">
      <div class="block col" style="flex-grow: 1">
        <div class="block__inner">
          <div class="block__header">
            License Manager
          </div>
          <div class="block__body">
            <div class="row">
              <div class="col-lg-3 mb-3">
                <BaseInputString
                    v-model="reqData.apiKey"
                    :is-required="true"
                    :error="errors.apiKey"
                    name="API Key"
                />
              </div>
              <div class="col-lg-3 mb-3">
                <BaseInputPassword
                    v-model="reqData.apiKeyPassword"
                    :is-required="true"
                    :error="errors.apiKeyPassword"
                    name="Api Key Password"
                />
              </div>
              <div class="col-lg-3 mb-3">
                <BaseInputString
                    :error="errors.dockerName"
                    v-model="reqData.dockerName"
                    name="Docker Name"
                    type="text"
                />
              </div>
              <div class="col-lg-3 mb-3" style="display: none">
                <BaseInputString
                    v-model="reqData.platformUrl"
                    :error="errors.platformUrl"
                    :is-required="true"
                    name="Get License Url"
                />
              </div>
              <div class="col activate-row">
                <div>
                  <div class="base-input-label" style="margin-bottom: 12px;">&nbsp;</div>
                  <base-button @click="activate">Activate</base-button>
                  <div v-if="activateResp.message" class="col-12 activate-message">
                    <span :class="`${activateResp.success ? 'success' : 'error'}`">{{activateResp.message}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="block col mt-4" style="flex-grow: 1">
        <div class="block__inner">
          <div class="block__header">
            Manual Activation
          </div>
          <div class="block__body">
            <div class="row">
              <div class="col col-lg-auto" style="flex-grow: 1;">
                <div class="row mb-3">
                  <div class="col r-systemId">
                    <BaseInputString
                        :model-value="store.serverInfo?.systemId"
                        :error="errors.systemId"
                        name="System ID"
                        :readonly="true"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col" style="flex-grow: 1;">
                    <BaseInputString
                        v-model="manualLicense"
                        name="Manual activation license"
                    />
                  </div>
                  <div class="col-auto">
                    <div style="margin-bottom: 12px;">&nbsp;</div>
                    <base-button @click="setManualLicense" style="margin-left: auto">Activate</base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col col-lg-auto" style="flex-grow: 1;">
                <div class="row mb-3">
                  <div class="col" style="flex-grow: 1;">
                    <BaseInputString
                        v-model="manualCredits"
                        name="Manual credit"
                        :disabled="!store.serverInfo?.isCreditPlug"
                    />
                  </div>
                  <div class="col-auto">
                    <div style="margin-bottom: 12px;">&nbsp;</div>
                    <base-button @click="setManualCredits" :disabled="!store.serverInfo?.isCreditPlug" style="margin-left: auto">Get Credit</base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block col-auto mt-4" style="max-width: 500px;">
        <div class="block__inner">
          <div class="block__header">
            Actions
          </div>
          <div class="block__body">
            <div class="row">
<!--              <div class="col-lg-6 mb-3">-->
<!--                <div style="margin-bottom: 12px;">&nbsp;</div>-->
<!--                <base-button @click="isShowReset = true"-->
<!--                             style="background: transparent; border: solid 1px #dddddd;">-->
<!--                  Reset Server-->
<!--                </base-button>-->
<!--              </div>-->
              <div class="col-lg-6 mb-3">
                <div style="margin-bottom: 12px;">&nbsp;</div>
                <form method="post" :action="downloadLogsUrl" ref="downloadLogsForm" target="_blank">
                  <base-button @click="downloadLogs"
                               style="background: transparent; border: solid 1px #dddddd;">
                    Download logs
                  </base-button>
                </form>
              </div>
              <div class="col-lg-6 mb-3">
                <div style="margin-bottom: 12px;">&nbsp;</div>
                <base-button @click="sendLogs"
                             style="background: transparent; border: solid 1px #dddddd;">
                  Send logs to Platform
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :visible="showActivationResponse" :close="closeActivationResponse" className="form">
      <div class="header">
        <div class="title">
          {{ activationResponse.title }}
        </div>
        <div class="modal-close" @click="closeActivationResponse"><close/></div>
      </div>
      <div class="body">
        <div class="d-flex justify-content-center icon">
            <SuccessLarge v-if="activationResponse.success"/>
            <ErrorLarge v-else/>
        </div>
        <div class="d-flex flex-column justify-content-center title">
          <div class="text-center mt-2" style="font-size: 24px">
            {{ activationResponse.message }}
          </div>
        </div>
      </div>
    </modal>
    <confirmation-modal :show="isShowReset" @approve="resetLicense" @close="isShowReset = false">
      <template #title>
        Are you sure want to reset server?
      </template>
    </confirmation-modal>
    <BasePreloader :show="isShowPreloader"/>
  </div>
</template>

<script>
import BaseButton from "../controls/BaseButton";
import BaseInputString from "../controls/BaseInputString";
import BaseInputPassword from "../controls/BaseInputPassword";
import BaseDropdown from "../controls/BaseDropdown";
import {computed, onMounted, ref} from "vue";
import {useAppStore} from "@/store/appStore";
import {baseUrl} from "@/config";
import ConfirmationModal from "../modals/ConfirmationModal";
import BasePreloader from "../controls/BasePreloader";
import BaseCheckbox from "../controls/BaseCheckbox";
import Close from '@/assets/icons/close.svg';
import SuccessLarge from '@/assets/icons/success-large.svg';
import ErrorLarge from '@/assets/icons/error-large.svg';

export default {
  components: {
    BaseCheckbox,
    BasePreloader,
    ConfirmationModal,
    BaseButton,
    BaseInputString,
    BaseInputPassword,
    BaseDropdown,
    Close,
    SuccessLarge,
    ErrorLarge,
  },
  props: {},
  setup(props) {
    function openItem(site) {
    }

    const store = useAppStore();

    const isShowReset = ref(false)
    const isShowPreloader = ref(false)

    const errors = ref({
      dockerName: "",
      apiKey: "",
      apiKeyPassword: "",
      platformUrl: "",
      systemId: "",
    })

    const reqData = ref({
      dockerName: "",
      apiKey: "",
      apiKeyPassword: "",
      platformUrl: "",
    })

    store.getConfig().then(data => {
      reqData.value = {
        ...data
      };
    })

    const activateResp = ref({
      success: false,
      message: ""
    })

    const activate = () => {
      errors.value = {}
      isShowPreloader.value = true

      store.activate(reqData.value)
          .then((resp) => {
            if (resp.data.fieldErrors) {
              errors.value = {
                ...resp.data.fieldErrors
              }
            }

            activationResponse.value.success = resp.data.success
            if(resp.data.success){
              activationResponse.value = {
                ...activationResponse.value,
                title: "Activation successful",
                message: "Successful activated",
              }
            } else{
              activationResponse.value = {
                ...activationResponse.value,
                title: "Activation failed",
                message: resp.data.error,
              }
            }

          })
          .finally(() => {
            showActivationResponse.value = true
            isShowPreloader.value = false
          })
    }

    const manualLicense = ref("")
    const setManualLicense = () => {
      isShowPreloader.value = true
      store.setLicense(manualLicense.value)
          .then((data) => {
            if (data.success) {
              activationResponse.value = {
                success: true,
                message: data.data
              }
            } else {
              activationResponse.value = {
                success: false,
                message: data.error
              }
            }
          })
          .finally(() => {
            activationResponse.value.title = "Manual activation license"
            showActivationResponse.value = true
            isShowPreloader.value = false
          })
    }

    const manualCredits = ref("")
    const setManualCredits = () => {
      isShowPreloader.value = true
      store.setCredits(manualCredits.value)
          .then((data) => {
            if (data.success) {
              activationResponse.value = {
                success: true,
                message: data.data
              }
            } else {
              activationResponse.value = {
                success: false,
                message: data.error
              }
            }
          })
          .finally(() => {
            activationResponse.value.title = "Manual credit"
            showActivationResponse.value = true
            isShowPreloader.value = false
          })
    }

    const resetLicense = () => {
      isShowReset.value = false;
      isShowPreloader.value = true

      store.deleteLicense()
          .finally(() => {
        isShowPreloader.value = false
      })
    }

    const downloadLogsForm = ref(null);

    const downloadLogs = () => {
      downloadLogsForm.value.submit();
    }

    const sendLogs = () => {
      store.sendLogs();
    }

    const activationResponse = ref({
      success: false,
      message: "",
      title: "",
    })
    const showActivationResponse = ref(false)

    const closeActivationResponse = () => {
      showActivationResponse.value = false
    }

    return {
      activationResponse,
      showActivationResponse,
      closeActivationResponse,

      errors,
      isShowPreloader,
      activateResp,
      isShowReset,
      downloadLogsForm,
      downloadLogsUrl: `${baseUrl}/log/pack`,
      downloadLogs,
      sendLogs,
      reqData,
      store,
      openItem,
      activate,
      manualLicense,
      setManualLicense,
      manualCredits,
      setManualCredits,
      resetLicense,
    };
  },
}
</script>

<style scoped lang="scss">
.home-page {
  width: 100%;
  border-radius: 12px;
}

.block {
  &__inner {
    background-color: #182339;
    border-radius: 12px;
    height: 100%;
  }

  &__header {
    font-weight: 700;
    font-size: 18px;
    padding: 20px;
    border-bottom: solid 1px #a3aed0;;
  }

  &__body {
    padding: 20px;
  }
}

.r-systemId {
  &:deep(input) {
    text-align: center;
  }
}

.activate-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.activate-message{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  span {
    font-size: 14px;

    &.success {
      color: greenyellow;
    }

    &.error {
      color: red;
    }
  }
}

</style>