<template>
  <div class="base-btn" :class="className" @click="onClick">
    <BasePreloader :show="loading" />
    <slot></slot>
  </div>
</template>

<script>

import BasePreloader from './BasePreloader';

export default {
  components: {BasePreloader},
  name: 'BaseButton',
  props: {
    loading: Boolean,
    outline: Boolean,
    add: Boolean,
    white: Boolean,
    icon: Boolean,
    disabled: Boolean,
    class: {
      type: String,
      default: ''
    },
  },
  emits: ['click'],
  computed: {
    className(){
      let classes = this.class;
      if(this.outline) classes += ' base-btn-outline';
      if(this.add) classes += ' base-btn-add';
      if(this.white) classes += ' base-btn-white';
      if(this.icon) classes += ' base-btn-icon';
      if(this.disabled || this.loading) classes += ' disabled';
      return classes;
    }
  },
  methods: {
    onClick(e){
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-btn{
    
  }
</style>
