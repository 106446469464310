<template>
  <layout v-if="store.authorized"/>
  <login v-else/>
</template>

<script>
import Layout from './Layout'
import {useAppStore} from "@/store/appStore";
import Login from "@/components/pages/Login";
export default {
  name: 'App',
  components: {Login, Layout},
  setup() {
    const store = useAppStore();
    store.getServerInfo();
    const config = store.getConfig();

    return {store}
  },
}
</script>
